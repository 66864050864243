import { Container } from "../../common/styles"
import tw, { styled } from "twin.macro"

export const Nav = styled.nav`
  ${tw`bg-primary top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-3 xl:px-8  py-2 shadow-lg transition-all duration-500`};

  &.transparent {
    ${tw`bg-transparent shadow-none`}
  }
`
export const NavContainer = styled(Container)`
  ${tw`flex flex-wrap items-center justify-between w-full px-1`}
`
export const NavLeft = styled.div`
  ${tw`flex justify-between items-center w-full lg:w-auto`}
`

export const Hamburger = styled.button`
  ${tw`cursor-pointer text-xl block lg:hidden outline-none focus:outline-none text-white`}

  & svg {
    ${tw`fill-current`}

    .transparent & {
      ${tw`text-primary`}
    }
  }
`

export const NavRight = styled.div`
  ${tw`hidden lg:flex flex-grow items-center`}

  &.open {
    ${tw`block pt-3 transition`}

    .transparent & {
      ${tw`pt-0 mt-4 bg-white rounded-lg shadow-lg`}
    }
  }
`

export const NavItems = styled.ul`
  ${tw`lg:flex items-center list-none ml-auto`}
`

export const NavItem = styled.li`
  a {
    ${tw`text-white hover:text-gray-300 px-2 xl:px-3 py-2 flex items-center text-sm uppercase font-bold transition`}

    .open & {
      ${tw`py-3 px-0 block text-center text-white hover:bg-primary hover:bg-opacity-25`}
    }

    .transparent & {
      ${tw`text-primary hover:text-green-600 hover:bg-opacity-5`}
    }
  }
  .open {
    &:not(:last-of-type) {
      a {
        ${tw`border-b border-white border-opacity-20`}
        .transparent .open & {
          ${tw`border-primary border-opacity-20`}
        }
      }
    }
  }

  &:last-of-type {
    a {
      ${tw`bg-tertiary lg:ml-4 px-6 xl:px-10 text-primary rounded-lg lg:rounded-full hover:bg-white hover:text-primary`}
      .transparent & {
        ${tw`bg-primary border-2 border-primary text-white hover:bg-transparent hover:text-primary `}
      }
    }
  }
`
