import React from "react"
import { FaInstagram } from "react-icons/fa"

import * as Styled from "./styles"

export default function Footer() {
  return (
    <Styled.Footer>
      <Styled.SocialMedia>
        <a
          href="https://www.instagram.com/carbonneutral_web_japan/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram />
        </a>
      </Styled.SocialMedia>
      <Styled.FooterWrap>
        <Styled.Copyright>
          Copyright © {new Date().getFullYear()} Carbon Neutral Web.
        </Styled.Copyright>
      </Styled.FooterWrap>
    </Styled.Footer>
  )
}
