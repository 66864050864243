import tw, { styled } from "twin.macro"

export const Logo = styled.div`
  ${tw`text-white text-sm lg:text-xl font-bold leading-relaxed inline-block lg:py-2 whitespace-nowrap uppercase w-48 lg:w-56 xl:w-72`}
  .text {
    fill: white;
  }

  .logo {
    fill: white;
  }

  .transparent & {
    .logo {
      fill: #537d54;
    }
    .text {
      fill: #070202;
    }
  }
`
