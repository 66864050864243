import React, { useState, useEffect } from "react"
import * as Styled from "./styles"

import { FaBars, FaTimes } from "react-icons/fa"
import Logo from "../Logo"

const menuItems = [
  { link: "calculator", title: "CO₂排出計算" },
  { link: "problems", title: "ウェブの環境負荷" },
  { link: "certificate", title: "認証マーク" },
  { link: "solutions", title: "CNWの取り組み" },
  { link: "merits", title: "メリット" },
  { link: "price", title: "料金" },
  { link: "offset", title: "お問い合わせ" },
]

export default function Navbar() {
  const [transparent, setTransparent] = useState(true)
  const [navbarOpen, setNavbarOpen] = React.useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setTransparent(false)
      } else {
        setTransparent(true)
      }
    }

    window.addEventListener("scroll", handleScroll)
  })

  return (
    <Styled.Nav className={transparent ? "transparent" : ""}>
      <Styled.NavContainer>
        <Styled.NavLeft>
          <Logo />
          {/* <Styled.Logo>/Styled.Logo> */}
          <Styled.Hamburger
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            {navbarOpen ? <FaTimes /> : <FaBars />}
          </Styled.Hamburger>
        </Styled.NavLeft>
        <Styled.NavRight className={navbarOpen && "open"}>
          <Styled.NavItems>
            {menuItems.map(({ link, title }, i) => (
              <Styled.NavItem key={i}>
                <a href={`/#${link}`}>{title}</a>
              </Styled.NavItem>
            ))}
          </Styled.NavItems>
        </Styled.NavRight>
      </Styled.NavContainer>
    </Styled.Nav>
  )
}
