import tw, { styled } from "twin.macro"

export const Container = styled.div`
  ${tw`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8`}
`

export const Section = styled.section`
  ${tw`pt-24 pb-52 relative`}
`

export const SectionTitle = styled.h2`
  ${tw`mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl`}
`

export const SectionSubTitle = styled.span`
  ${tw`text-base text-green-600 font-semibold tracking-wide uppercase block`}
`

export const SectionHeader = styled.div`
  ${tw`lg:text-center`}
`

export const SectionText = styled.p`
  ${tw`mt-4 mb-8 max-w-3xl md:text-xl leading-relaxed text-gray-500 lg:mx-auto`}
`

export const FlexWrapper = styled.div`
  ${tw`flex-col lg:flex-row flex gap-y-16 lg:gap-y-0 lg:gap-x-8`}
`

export const GridWrapper = styled.div`
  ${tw`space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 `}
`
